const SERVER_URL = "https://rhipposerver.rhizom.co";
const WEBSITE_URL = "https://rhippo.rhizom.co";


async function fetchAccessToken() {
    try {
        const response = await fetch(`${SERVER_URL}/api/token`, {
            method: 'POST', // Ensure the method is POST
            credentials: 'include', // Include cookies in the request
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Not authenticated');
        }

        const data = await response.json();
        console.log('Access Token:', data.accessToken);
        return data.accessToken; // Use the access token to make API requests
    } catch (error) {
        console.error('Error fetching access token:', error);
        return null;
    }
}


async function grab_LLM_brains(accessToken) {
    console.log("USING TOKEN TO RETRIEVE LLM BRAINS:", accessToken)
    
    const response = await fetch(`${SERVER_URL}/init-grab-LLM-brains`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ drive_access_token: accessToken })
    });

    const data = await response.json();
    
    return data
}


async function run_update_LLM_memory(selected_LLM_brain_identifier, persona_string, form_data, updated_prior_context) {
    const response = await fetch(`${SERVER_URL}/update_LLM_brain_memory`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            LLM_brain_identifier: selected_LLM_brain_identifier, // Replace with the actual brain name
            persona_string: persona_string, // Replace with the actual persona string
            form_answers: form_data,
            prior_context: updated_prior_context,
        })
    });
    const data = await response.json();
    return data
}


async function delete_LLM_brain(selected_LLM_brain_identifier) {
    const response = await fetch(`${SERVER_URL}/delete_LLM_brain`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            LLM_brain_identifier: selected_LLM_brain_identifier, // Replace with the actual brain name
        })
    });
    const data = await response.json();
    return data
}

async function share_LLM_brain(LLM_brain_identifier, emails) {
    const response = await fetch(`${SERVER_URL}/share-LLM-brain`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            LLM_brain_identifier: LLM_brain_identifier, // Replace with the actual brain name
            share_emails: emails, // Replace with the actual persona string
        })
    });
    const data = await response.json();
    return data
}

async function check_LLM_name_validity(name) {
    const response = await fetch(`${SERVER_URL}/check-LLM-name-validity`, {
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            LLM_brain_name: name
        })
    });
    const data = await response.json();
    return data
}

async function initialize_new_LLM_brain(new_LLM_info) {
    await fetch(`${SERVER_URL}/initialize_new_LLM_brain`, { 
        method: 'POST',
        credentials: 'include', // Include cookies in the request
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            LLM_brain_name: new_LLM_info["name"], // Replace with the actual brain name
            persona_string: new_LLM_info["persona_string"], // Replace with the actual persona string
            prior_context: new_LLM_info["prior_context"], // Replace with the actual boolean value
            form_answers: new_LLM_info["form"]
        }) 
    })
    return true
}


// --- export to other files ---


export default {
    SERVER_URL,
    WEBSITE_URL,
    fetchAccessToken,
    grab_LLM_brains,
    run_update_LLM_memory,
    delete_LLM_brain,
    share_LLM_brain,
    check_LLM_name_validity,
    initialize_new_LLM_brain
  };