import server_ops from './server_operations.js';
const {SERVER_URL,
    WEBSITE_URL,
    fetchAccessToken,
    grab_LLM_brains,
    run_update_LLM_memory,
    delete_LLM_brain,
    share_LLM_brain,
    check_LLM_name_validity,
    initialize_new_LLM_brain} = server_ops;

// Setup slack button with correct link
// document.getElementById("add-to-slack").setAttribute("href", "https://slack.com/oauth/v2/authorize?client_id=6540552183617.7419169483764&scope=app_mentions%3Aread%2Cchannels%3Aread%2Cchat%3Awrite%2Cchat%3Awrite.customize%2Cchat%3Awrite.public%2Cgroups%3Ahistory%2Cgroups%3Aread%2Cim%3Ahistory%2Cim%3Aread%2Cim%3Awrite%2Cmpim%3Ahistory%2Cmpim%3Aread%2Creactions%3Aread%2Creactions%3Awrite%2Cusers%3Aread%2Cusers%3Aread.email&user_scope=&redirect_uri=https%3A%2F%2Ffinch-renewing-kitten.ngrok-free.app%2Fslack_oauth_callback")

function loginThenGoToMain(prompt_value) {
    const clientId = '610959923235-ciq4lmqfhes2dv2mg9j9c92n2l6k4p32.apps.googleusercontent.com';
    const redirectUri = `${SERVER_URL}/oauth2callback`; // Your server URL
    const scope = 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/contacts.readonly';
    // TODO: CHANGES THIS TO RHIZOM!!!
    const state = encodeURIComponent(JSON.stringify({ redirectUrl: `${WEBSITE_URL}/main.html` }));
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}&access_type=offline&prompt=${prompt_value}&state=${state}`;
    window.location.href = authUrl;
}

function runLogin() {
    fetchAccessToken().then(
        access_token => {
            let do_force_relog = localStorage.getItem("force_relog") == "true"
            console.log("DOING RELOG?", do_force_relog)
            if (access_token && !do_force_relog) {
                window.location.replace("./main.html");
            } else {
                let prompt_value;
                if (do_force_relog) {
                    prompt_value = "select_account consent"
                } else {
                    prompt_value = "consent"
                }
                console.log("prompt_value?", prompt_value)
                loginThenGoToMain(prompt_value);
                localStorage.setItem("force_relog", "false")
            }
        }
    )
}

document.getElementById('loginButton').addEventListener('click', runLogin);

document.getElementById('get-started-button').addEventListener('click', runLogin);

document.getElementById('privacy-policy-button').addEventListener('click', () => {window.location.replace("/privacy_policy.html")})


// Fetch the access token once the user is authenticated
window.addEventListener('load', fetchAccessToken);

